import {BaseTab} from "./tacho_shared";

export default class VehicleUploadsTab extends BaseTab
{
    renderContent($table, content) {
        const $tbody = $table.children('tbody')
        $tbody.empty();

        if(content.data.length === 0) {
            $tbody.append(`<tr><td colspan="5">No Uploads Found</td></tr>`);
            return;
        }

        for (const index in content.data) {
            const row = content.data[index];
            const $row = $('<tr>');

            for (const k in row) {
                const $column = $('<td>').html(row[k]);
                $row.append($column);
            }
            $tbody.append($row);
        }
    }
}